import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(() => {
  const { isAccountsEnabled } = useRuntimeConfig().public.featureFlags;

  if (!isAccountsEnabled) return navigateTo(useLocalePath()({ name: 'shop' }));

  // If we are logged in already, redirect to the dashboard
  const authStore = useAuthStore();
  const { isLoggedIn } = storeToRefs(authStore);

  if (isLoggedIn.value) {
    return navigateTo(useDashboardLandingPage());
  }

  return true;
});
